<!-- eslint-disable -->
<template>
    <div class="card-preview" v-if="template.body">
        <div v-if="template.type == 'DEFAULT' && template.buttons.type !== 'OTP' && template.body.add_security_recommendation == undefined">
            <div class="card" style="width: 300px;">
                <div class="card-body">
                    <header class="card-text" v-if="template.headerType === 'TEXT'">
                        <b>{{ template.headers.TEXT.body }}</b>
                    </header>
                    <header v-else-if="template.headerType === 'IMAGE'">
                        <div class="media">
                            <img :src="headerCurrentImage" class="image-default-header" v-if="headerCurrentImage" />
                        </div>
                    </header>
                    <header v-else-if="template.headerType === 'VIDEO'">
                        <div class="media">
                            <video :src="headerCurrentVideo" v-if="headerCurrentVideo" autoplay></video>
                        </div>
                    </header>
                    <header v-else-if="template.headerType === 'DOCUMENT'">
                        <div class="media">
                            <object type="application/pdf" :data="headerCurrentDocument"
                                v-if="headerCurrentDocument"></object>
                        </div>
                    </header>
                    <div class="card-text">{{ template.body.text }} <span class="time-span">10:20</span></div>
                    <footer class="card-text footer-item" v-if="template.footer">{{ template.footer.body }}</footer>
                    <template v-if="template.buttons.type === 'QUICK_REPLY' &&  template.quick_replies">
                        <template v-for="(reply, i) in template.quick_replies.filter((a) => !!a)">
                            <div class="btn-whats-message" :key="i" :class="{ last: i === 2 }">
                                <a><span class="material-symbols-outlined to-left">reply</span> {{ reply }}</a>
                            </div>
                        </template>
                    </template>
                    <template v-if="template.buttons.type === 'CALL_TO_ACTION'">
                        <div class="btn-whats-message" v-if="template.call_to_action_dial_phone.text">
                            <a><span class="material-symbols-outlined to-left">phone</span> {{
        template.call_to_action_dial_phone.text }}</a>
                        </div>
                        <div class="btn-whats-message" v-if="template.call_to_action_website.text">
                            <a><span class="material-symbols-outlined to-left">open_in_new</span> {{
        template.call_to_action_website.text }}</a>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div v-if="template.type == 'AUTH' || template.buttons.type === 'OTP' || template.body.add_security_recommendation != undefined">
            <div class="card" style="width: 300px;">
                <div class="card-body">
                    <p class="m-b-0">{{ auth_messages[template.language].message }} <strong
                            style="font-weight: 600;">DEMO</strong></p>
                    <p v-if="template.footer && template.footer.code_expiration_minutes" class="minutes-info">
                        {{ auth_messages[template.language].time }} {{ template.footer.code_expiration_minutes }} minutos
                    </p>
                    <div class="card-text">{{ template.body.text }} <span class="time-span">10:20</span></div>
                    <div class="btn-whats-message">
                        <a><span class="material-symbols-outlined to-left">content_copy</span> {{auth_messages[template.language].copy}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="template.type == 'CAROUSEL'">
            <div class="card" style="margin-bottom: 10px;">
                <div class="card-body">
                    <div class="card-text">{{ template.body.text }} <span class="time-span">10:20</span></div>
                    <footer class="card-text footer-item" v-if="template.footer">{{ template.footer.body }}</footer>
                </div>
            </div>
            <div style="overflow-x: auto;" class="d-flex">
                <div v-for="(card, n) in template.carousel.content.cards" :key="n" class="col-8"
                    style="padding: 0px; padding-right: 10px;">
                    <div class="card card-whats-message">
                        <div class="card-content">
                            <div class="card-img d-flex">
                                <img class="card-img-top img-fluid" style="height: 160px;"
                                    :src="card.components[0].example.header_handle[0]" alt="Card image cap" />
                            </div>
                            <div class="card-body">
                                <div class="card-text"> {{ card.components[1].text }} </div>
                                <div class="btn-whats-message">
                                    <a><span class="material-symbols-outlined to-left">open_in_new</span> Web site</a>
                                </div>
                                <div class="btn-whats-message">
                                    <a><span class="material-symbols-outlined to-left">reply</span> Quick Replay</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="template.type == 'CATALOG'">
            <div style="overflow-x: auto;" class="d-flex">
                <div class="col-9" style="padding: 0px; padding-right: 10px;">
                    <div class="card card-whats-message">
                        <div class="card-content card-catalog">
                            <div class="card-img d-flex">
                                <div class="prod-preview">
                                    <img class="card-img-top img-fluid"
                                        :src="'https://framerusercontent.com/images/45uA4nlH9K2klcAVTjkwsps0A0I.png'"
                                        alt="Card image cap" />
                                    <div>
                                        <h3>Catálogo de exemplo</h3>
                                        <p>Lorem Ipsum is simply dummy</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <p class="card-text">
                                    {{ template.body.text }} <span class="time-span">10:20</span>
                                </p>
                                <footer class="card-text footer-item" v-if="template.footer">{{ template.footer.body }}
                                </footer>
                                <div class="btn-whats-message">
                                    <a>Ver catálogo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="template.type == 'MPM'">
            <div style="overflow-x: auto;" class="d-flex">
                <div class="col-9" style="padding: 0px; padding-right: 10px;">
                    <div class="card card-whats-message">
                        <div class="card-content card-catalog">
                            <div class="card-img d-flex">
                                <div class="prod-preview">
                                    <img class="card-img-top img-fluid"
                                        src="https://framerusercontent.com/images/45uA4nlH9K2klcAVTjkwsps0A0I.png"
                                        alt="Card image cap"
                                        style="width: 70px; height: 50px; padding: 0px !important;" />
                                    <div>
                                        <h3>{{ template.header.body }}</h3>
                                        <p>10 itens</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <p class="card-text">
                                    {{ template.body.text }} <span class="time-span">10:20</span>
                                </p>
                                <footer class="card-text footer-item" v-if="template.footer">{{ template.footer.body }}
                                </footer>
                                <div class="btn-whats-message">
                                    <a>Ver produtos</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="template.type == 'OFFER'">
            <div style="overflow-x: auto;" class="d-flex">
                <div class="col-9" style="padding: 0px; padding-right: 10px;">
                    <div class="card card-whats-message">
                        <div class="card-content card-catalog">
                            <div class="card-img d-flex">
                                <img v-if="headerType === 'IMAGE'" class="card-img-top img-fluid h-160"
                                    :src="headerCurrentImage" alt="Card image cap" />
                                <header v-else>
                                    <div class="media">
                                        <video class="video-header" :src="headerCurrentVideo" v-if="headerCurrentVideo"
                                            autoplay></video>
                                    </div>
                                </header>
                            </div>
                            <div class="card-img d-flex">
                                <div class="prod-preview">
                                    <div class="offer-icon">
                                        <span class="material-symbols-outlined">
                                            redeem
                                        </span>
                                    </div>
                                    <div>
                                        <h3>{{ template.limited.text }}</h3>
                                        <p>Offer end on Apr 23</p>
                                        <p class="offer-code">Code YUP50</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <p class="card-text">
                                    {{ template.body.text }} <span class="time-span">10:20</span>
                                </p>
                                <footer class="card-text footer-item" v-if="template.footer">{{ template.footer.body }}
                                </footer>
                                <div class="btn-whats-message">
                                    <a><span class="material-symbols-outlined to-left">content_copy</span> Copiar
                                        código</a>
                                </div>
                                <div class="btn-whats-message">
                                    <a><span class="material-symbols-outlined to-left">open_in_new</span> Acessar
                                        loja</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="template.type == 'CUPOM'">
            <div style="overflow-x: auto;" class="d-flex">
                <div class="col-9" style="padding: 0px; padding-right: 10px;">
                    <div class="card card-whats-message">
                        <div class="card-content card-catalog">
                            <div class="card-body">
                                <header class="card-text padding-b-0" v-if="headerType === 'TEXT'">
                                    <b>{{ headerPreview }}</b>
                                </header>
                                <header v-else-if="headerType === 'IMAGE'">
                                    <div class="media">
                                        <img :src="headerCurrentImage" v-if="headerCurrentImage" />
                                    </div>
                                </header>
                                <header v-else-if="headerType === 'VIDEO'">
                                    <div class="media">
                                        <video :src="headerCurrentVideo" v-if="headerCurrentVideo" autoplay></video>
                                    </div>
                                </header>
                                <header v-else-if="headerType === 'DOCUMENT'">
                                    <div class="media">
                                        <object type="application/pdf" :data="headerCurrentDocument"
                                            v-if="headerCurrentDocument"></object>
                                    </div>
                                </header>
                                <p class="card-text">
                                    {{ template.body.text }} <span class="time-span">10:20</span>
                                </p>
                                <footer class="card-text footer-item padding-t-0" v-if="template.footer">{{
        template.footer.body
                                    }}</footer>
                                <div class="btn-whats-message btn-whats-message-coupon">
                                    <a><span class="material-symbols-outlined to-left">content_copy</span>
                                        {{template.buttons.call_to_copy_code.text}}</a>
                                </div>
                                <div class="btn-whats-message btn-whats-message-coupon"
                                    style="border: none; display: none;">
                                    <a><span class="material-symbols-outlined to-left">open_in_new</span> Acessar
                                        loja</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
export default {
    name: 'PreviewTemplate',
    components: {
    },
    props: ['template'],
    watch: {
        template(newValue) {
            newValue.type = 'DEFAULT';
            if (newValue.buttons) {
                if (newValue.buttons.type === 'CATALOG') {
                    newValue.button_index = 0;
                    newValue.type = 'CATALOG';
                    newValue.button_parameter = [];
                }
                if (newValue.buttons.type === 'COPY_CODE') {
                    newValue.button_index = 0;
                    newValue.type = 'CUPOM';
                    newValue.button_parameter = '';
                }
                if (newValue.buttons.type === 'MPM') {
                    newValue.type = 'MPM';
                }
                if (newValue.limited !== undefined) {
                    newValue.type = 'OFFER';
                    newValue.expiration_time_ms = 1;
                }
            } else {
                newValue.buttons = {};
            }

            if (newValue.carousel) {
                newValue.carousel.content = JSON.parse(newValue.carousel.content);
                newValue.type = 'CAROUSEL';
            }
            this.template = newValue;
        },
    },
    computed: {
        headerType() {
            return this.template?.header?.type || null;
        },
        headerBody() {
            return this.template?.header?.body || '';
        },
        customHeader() {
            const header = this.template?.header;
            return header?.type && (header?.type !== 'TEXT' || header.body?.indexOf('{{1}}') !== -1);
        },
        templateBodyParams() {
            const body = this.template?.body;

            return body && [...body?.text?.matchAll(/\{\{\d+}\}/g)].map((param, index) => {
                if (body.example_parameters[index]) return body.example_parameters[index];

                return `Parametro {{${index + 1}}}`;
            });
        },
        customizable() {
            return this.customHeader || this.templateBodyParams?.length > 0;
        },
        headerCurrentVideo() {
            if (this.headerType === 'VIDEO') {
                return this.headerVideo || this.template?.header?.['example_parameter'];
            }
            return null;
        },
        headerCurrentDocument() {
            if (this.headerType === 'DOCUMENT') {
                return this.headerDocument || this.template?.header?.['example_parameter'];
            }

            return null;
        },
        headerCurrentImage() {
            if (this.headerType === 'IMAGE') {
                return this.headerImage || this.template?.header?.['example_parameter'];
            }

            return null;
        },
        headerPreview() {
            return this.template?.header?.body?.replace(/\{\{1\}\}/, (param) => this.headerParam || param);
        },
        bodyPreview() {
            return this.template?.body?.text?.replace(/\{\{(\d+)\}\}/g, (param) => {
                const index = param.slice(2, -2) - 1;

                return this.bodyParams[index] || param;
            });
        },
        headerResults() {
            const result = this.template?.header?.body;

            return !result ? '' : result.replace(/\{\{1\}\}/, (param) => this.headerParam || '');
        },
        bodyResults() {
            return this.template?.body?.text?.replace(/\{\{(\d+)\}\}/g, (param) => {
                const index = param.slice(2, -2) - 1;

                return this.bodyParams[index] ?? null;
            });
        },
        footerBody() {
            return this.template?.footer?.body;
        },
    },
    data() {
        return {
            auth_messages: {
                pt_BR: {
                    message: 'Seu código de verificação é',
                    time: 'Esse código expira em',
                    copy: 'Copiar código',
                },
                pt_PT: {
                    message: 'Seu código de verificação é',
                    time: 'Esse código expira em',
                    copy: 'Copiar código',
                },
                es: {
                    message: 'Tu código de verificación es',
                    time: 'Este código caduca en',
                    copy: 'Copiar código',
                },
                fr: {
                    message: 'Votre code de vérification est',
                    time: 'Ce code expire dans',
                    copy: 'Copier le code',
                },
                en_US: {
                    message: 'Your verification code is',
                    time: 'This code expires in',
                    copy: 'Copy code',
                },
                de: {
                    message: 'Ihr Bestätigungscode lautet',
                    time: 'Dieser Code läuft ab',
                    copy: 'Code kopieren',
                },
                zh_CN: {
                    message: '您的驗證碼是',
                    time: '該代碼的有效期限為',
                    copy: '複製程式碼',
                },
            },
            // recebe string do parametro ou url de um arquivo
            headerParam: '',

            // Temporario para evitar reupload
            headerVideo: null,
            headerDocument: null,
            headerImage: null,
        };
    },
    created() {
        this.template.type = 'DEFAULT';
        if (this.template.buttons) {
            if (this.template.buttons.type === 'CATALOG') {
                this.template.button_index = 0;
                this.template.type = 'CATALOG';
                this.template.button_parameter = [];
            }
            if (this.template.buttons.type === 'COPY_CODE') {
                this.template.button_index = 0;
                this.template.type = 'CUPOM';
                this.template.button_parameter = '';
            }
            if (this.template.buttons.type === 'MPM') {
                this.template.type = 'MPM';
            }
            if (this.template.limited !== undefined) {
                this.template.type = 'OFFER';
                this.template.expiration_time_ms = 1;
            }
        } else {
            this.template.buttons = {};
        }

        if (this.template.carousel) {
            this.template.type = 'CAROUSEL';
            if (this.template.carousel.content.cards == undefined) {
                this.template.carousel.content = JSON.parse(this.template.carousel.content);
            }
        }
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style lang="scss" scoped>
.main {
    position: relative;
    min-height: 200px;

    .qt-loader {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1000;
        background: rgba(0, 0, 0, .32);
        height: 100%;
        width: 100%;

        &:before {
            top: 20%;
            left: 50%;
        }
    }

    .label-send-check {
        margin-left: 10px;
        margin-top: 2px;
    }

    .custom-control-inline {
        margin-right: 0 !important;
        padding-left: 0 !important;
    }
}

.btn-link-preview .btn {
    box-shadow: 0px 2px 3px #0000001A;
    color: var(--foreground);
}

.preview {
    border-radius: .2rem;
    background-color: #e5ddd5;
    box-sizing: border-box;
    // min-height: 400px;
    padding: 15px;
    position: relative;

    &::before {
        background: url('/assets/img/chat-bg.png');
        background-size: 366px 666px;
        content: "";
        opacity: .06;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
    }

    .preview-sample {
        max-width: 340px;
        position: sticky;
        top: 92px;
    }

    .preview-message,
    .preview-btns .btn {
        box-shadow: 0 1px 0.5px rgba(0, 0, 0, .15);
        background-color: #fff;
        border-radius: .32rem;
        width: 100%;
    }

    .preview-btns {
        margin: 0 !important;

        .btn {
            border: none;
            min-height: 32px;
            width: calc(50% - 0.25rem);
            margin: 0.25rem 0.25rem 0.5rem 0;

            &:nth-child(2) {
                margin: 0.25rem 0 0.5rem 0.25rem;
            }

            &.last {
                margin: 0.25rem 0 0.25rem 0;
                width: 100%;
            }
        }
    }

    .preview-message {
        border-top-left-radius: 0;
        padding: .25rem .25rem 0 .25rem;
        position: relative;
        z-index: 2;
        min-height: 32px;

        &>header,
        &>div,
        &>footer {
            margin-bottom: .25rem;
            padding: .25rem;
        }

        &>time {
            position: absolute;
            bottom: .25rem;
            right: .5rem;
            font-size: 75%;
        }

        &>footer {
            color: rgb(140, 140, 140);
        }

        &>header {
            overflow: hiddden;
            max-height: 280px;
            max-height: 100%;
            font-weight: bold;

            .media {
                min-height: 100px;
                border-radius: .32rem;
                background: #ececec;
                margin-bottom: .2rem;
                overflow: hidden;

                &>img,
                &>object,
                &>video {
                    object-fit: cover;
                    width: 100%;
                }

                &.document {
                    position: relative;
                    min-height: 60px;
                    height: 60px;

                    img {
                        position: absolute;
                        width: 26px;
                        height: 30px;
                        left: .5rem;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}

/* Rebranding */
.preview-div {
    display: flex;
    // position: relative;
    /* align-items: center; */
    position: sticky;
    top: 100px;
    height: fit-content;
}

.preview-wrapper {
    width: 100%;
    height: fit-content;
}

.phone-img {
    margin-left: auto;
    width: 20rem;
    height: fit-content;
}

@media (min-width: 768px) {
    ::v-deep label {
        font-size: 1rem !important;
    }

    .phone-img {
        position: absolute;
        // right: 0;
        right: 50px;
        padding-right: 3rem;
        margin-top: 10%;
        z-index: -1;
    }

    .preview-wrapper {
        /* position: sticky;
    top: 100px; */
        display: flex;
        align-items: center;
        height: 472px;
    }

    .card-preview {
        position: absolute;
        width: 410px;
        // top: 200px;
        right: -10%;
        margin-top: 4rem;
        margin-bottom: 0 !important;
    }

    .card-preview .card-body {
        padding: 3rem 1rem;
    }
}

/* btn Preview style */
.preview-message-btns {
    border-top: 1px solid #dadde1;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    padding: 5px;
    color: #00a5f4;
    font-size: 14px;
    line-height: 20px;

    .btn {
        &:nth-child(1) {
            float: left;
            width: 48%;
        }

        &:nth-child(2) {
            float: right;
            width: 48%;
        }

        &.max {
            width: 100%;
        }
    }
}

.preview-message-btns i {
    color: #00a5f4;
}

.preview-message-btns span {
    display: flex;
    margin-bottom: 8px;
    justify-content: center;
    gap: 1rem;
    margin-top: .725rem;
}

.c-pointer {
    cursor: pointer !important;
}

.media {
    video {
        max-height: 220px;
    }
}

.img-wrapper {
    display: flex;
    justify-content: center;
}

.card-options {
    position: absolute;
    top: -70px;
    right: 0;
    width: 330.633px;
    // min-width: 330.633px;
    // max-width: 330.633px;
    // min-height: 200px;
    background-color: #00000038;
    backdrop-filter: blur(10px);
    z-index: 5;

    .card-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #000000b2;
        color: #fff;
        padding: .285rem 1rem;

        button {
            height: fit-content;
        }
    }

    .card-body {
        background-color: #0000009c;
    }

    .form-control {
        background-color: #000000a1 !important;
        border-color: #000 !important;
        color: #fff;
    }

    .material-icons-outlined.chevron {
        transition: .5s;
        color: #fff;
    }

    .material-icons-outlined.chevron.open {
        transform: rotate(-180deg);
    }
}

.card-header {
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.midia-container-template {
    margin-bottom: 10px;
    background: #f5f5f5;
}

.card-header {
    font-weight: 700 !important;
}

.img-wrapper {
    margin-bottom: 10px;
}

.image-container {
    text-align: center;
    margin-bottom: 10px;
    background: #eff2f7;
    padding: 25px;
    width: 100%;
}

.image-container img {
    width: 20%;
}

.card-upload-btn {
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

.card-upload-btn:hover {
    color: #a4a4a4;
    font-size: 19px;
}

.edit-body-card-icon {}

.content::v-deep {
    .vti__dropdown {
        background: #fff;
    }
}

.category {
    color: #0d0d0d;

    .col-2 {
        text-align: center;

        &>i {
            font-size: 24pt;
        }
    }

    .col-10>h3,
    .col-2>i {
        color: inherit !important;
    }
}

.preview {
    border-radius: 0.2rem;
    background-color: #e5ddd5;
    box-sizing: border-box;
    height: 100%;
    padding: 15px;
    position: relative;

    &::before {
        background: url('/assets/img/chat-bg.png');
        background-size: 366px 666px;
        content: '';
        opacity: 0.06;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
    }

    .preview-sample {
        max-width: 340px;
        position: sticky;
        top: 92px;
    }

    .preview-message,
    .preview-btns .btn {
        box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
        background-color: #fff;
        border-radius: 0.32rem;
        width: 100%;
    }

    .preview-btns {
        margin: 0 !important;

        .btn {
            border: none;
            min-height: 32px;
            width: calc(50% - 0.25rem);
            margin: 0.25rem 0.25rem 0.5rem 0;

            &:nth-child(2) {
                margin: 0.25rem 0 0.5rem 0.25rem;
            }

            &.last {
                margin: 0.25rem 0 0.25rem 0;
                width: 100%;
            }
        }
    }

    .preview-message {
        border-top-left-radius: 0;
        padding: 0.25rem 0.25rem 0 0.25rem;
        position: relative;
        z-index: 2;
        min-height: 32px;

        &>header,
        &>div,
        &>footer {
            margin-bottom: 0.25rem;
            padding: 0.25rem;
        }

        &>time {
            position: absolute;
            bottom: 0.25rem;
            right: 0.5rem;
            font-size: 80%;
        }

        &>footer {
            color: rgb(140, 140, 140);
        }

        &>header {
            overflow: hiddden;
            max-height: 280px;
            max-height: 100%;
            font-weight: bold;

            .media {
                min-height: 100px;
                border-radius: 0.32rem;
                background: #ececec;
                margin-bottom: 0.2rem;
                overflow: hidden;

                &>img,
                &>object,
                &>video {
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
    }
}

/* Rebranding */
.body-section {
    position: relative;
}

.input-info {
    position: absolute;
    right: 20px;
    top: unset;
    color: #aaa;
    bottom: -30px;
}

.input-info.text-area {
    top: 270px;
}

.new-template-whats {
    padding: 15px 15px 33px;
    margin: 0 auto;
    background: var(--background-2);
    margin: 10px;
}

.new-template-title {
    color: rgb(28, 30, 33);
}

.darkmode .new-template-title,
.darkmode .new-template-whats p span {
    color: #fff;
}

.new-template-whats p span {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: normal;
    overflow-wrap: normal;
    text-align: left;
    color: rgb(96, 103, 112);
}

.carousel-visible {
    position: sticky;
    top: 100px;
}

.carousel-template .carousel-indicators li {
    width: 15px;
    height: 15px;
    background-color: var(--clr-yup-purple);
    border-radius: 50%;
}

.carousel-template::v-deep {
    .carousel-item[role='listitem'] {
        margin-bottom: 1.2rem !important;

        &>img {
            display: none !important;
        }
    }

    .carousel-caption {
        position: static !important;

        &>p {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}

.carousel-template::v-deep .carousel-indicators li {
    width: 15px;
    height: 15px;
    background-color: var(--clr-yup-purple);
    border-radius: 50%;
}

.carousel-template::v-deep .carousel-control-next-icon,
.carousel-template::v-deep .carousel-control-prev-icon {
    width: 100%;
    height: 45px;
    position: relative;
}

.carousel-template::v-deep .carousel-control-next-icon::after,
.carousel-template::v-deep .carousel-control-prev-icon::after {
    color: var(--clr-yup-purple);
    font-weight: 400;
    font-family: 'Material Icons';
    right: 0;
    top: 10px;
    width: 100%;
}

.carousel-template::v-deep .carousel-control-next-icon::after {
    content: '\e5e1' !important;
}

.carousel-template::v-deep .carousel-control-prev-icon::after {
    content: '\e2ea';
}

.carousel-indicators {
    bottom: -1.5rem;
}

.icon-template span {
    color: var(--clr-yup-purple);
    font-size: 122px;
}

.new-template-whats {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.optional-template-input {
    display: block;
    float: left;
    font-size: 12px;
    background: #f4f4f4;
    padding: 5px;
    border-radius: 10px;
    transform: translateY(-8px) translateX(-5px) scale(0.8);
}

.darkmode .optional-template-input {
    background: var(--background-3);
}

.button-icon {
    height: fit-content;
    padding: 0;
}

.media-icon {
    height: fit-content;
    font-size: 1.75rem;
    transition: 0.5s;
    padding: 9px;
    cursor: pointer;
}

.media-icon.active {
    color: var(--clr-yup-purple);
}

.media-icon:hover {
    background-color: var(--background-icon-hover);
    border-radius: 5px;
}

::v-deep .body-section .textarea-information {
    display: none;
}

::v-deep body {
    background-color: #fff;
}

.create-template-section {
    background: transparent;
    box-shadow: unset;
}

/* Phone preview */
.preview-div {
    display: flex;
    position: sticky;
    /* align-items: center; */
    height: fit-content;
    top: 100px;
}

.preview-wrapper {
    width: 100%;
    // height: fit-content; // Position-sticky
    // height: 512px;
    z-index: 1;
}

.phone-img {
    margin-left: auto;
    width: 20rem;
    height: fit-content;
}

.preview-btns .btn {
    color: var(--foreground);
}

.btn.last {
    width: 100%;
}

.card-preview {
    // position: absolute; // Position-sticky
    position: relative;
    width: 410px;
    // top: 200px; // Position-sticky
    // right: 20%; // Position-sticky
    right: -20px;
    margin-left: auto;
    margin-bottom: 1rem;
    min-height: 93px;
    --gap: 0.5rem;
    background: #efe7dc;
    border: solid 1px #e0e0e0;
}

@media (min-width: 768px) {
    ::v-deep label {
        font-size: 1rem !important;
    }

    .phone-img {
        position: absolute;
        right: 0;
        padding-right: 3rem;
        // margin-top: 10%; // Position-sticky
        z-index: -1;
    }

    .preview-wrapper {
        /* position: sticky;
    top: 100px; */
        display: flex;
        align-items: center;
        height: 512px;
        flex-wrap: wrap;
    }
    .card-preview {
        right: -20px;
    }
    .card-preview .card-body {
        padding: 1rem 1rem 2rem;
        display: flex;
        flex-direction: column;

        video {
            max-width: 200px;
            margin-left: auto;
            margin-right: auto;
        }

        img,
        object {
            margin-left: auto;
            margin-right: auto;
        }

        .img-loading {
            width: 378px;
            height: 189px;
        }
    }
}

.rebranding .card.card-preview .card-text.footer-item {
    color: var(--gray-font-color);
    margin-bottom: 0;
}

.card-preview:first-of-type {
    margin-top: calc(10 * var(--gap));
}

.card-preview:last-of-type {
    margin-bottom: calc(35 * var(--gap));
}

.card-preview:last-of-type .card-body {
    padding: 1rem;
}

.preview-btns {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.sort-group,
.create-group {
    width: auto;
}

.card-btn-action {
    background-color: var(--background-3);
}

/* btn Preview style */
.preview-message-btns {
    border-top: 1px solid #dadde1;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    padding: 5px;
    color: #00a5f4;
    font-size: 14px;
    line-height: 20px;
}

.preview-message-btns i {
    color: #00a5f4;
}

.preview-message-btns span {
    display: block;
    margin-bottom: 8px;
}

@media (min-width: 768px) and (max-width: 1650px) {
    .card-preview {
        margin-right: auto;
    }
}

.btn-wrapper {
    display: flex;
    align-items: center;
}

/* Preview */
.preview-wrapper {
    position: sticky;
    top: 90px;
}

.card-preview {
    padding: 10px;
    border-radius: 8px;
}

.card-preview .card {
    box-shadow: none !important;
    border-radius: 15px !important;
    overflow: hidden;
}

.card-preview .card-body {
    padding: 8px !important;
}

.card-preview .media {
    overflow: hidden;
    border-radius: 8px;
}

.type-row .card {
    flex: 1;
    margin: 3px;
}

.image-default-header {
    height: 150px;
    width: 100%;
    object-fit: cover;
}

.type-row span.material-symbols-outlined {
    font-size: 25px !important;
}

.btn-whats-message {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-top-color: #e0e0e0 !important;
    border-top-width: 1px !important;
    border-top: solid;
}

.btn-whats-message a {
    display: block;
    justify-content: center;
    padding: 5px;
    color: #389dc9 !important;
    font-weight: 500;
    font-size: 15px;
    margin-right: 5px;
    margin-top: 3px;
}

.btn-whats-message a span {
    font-size: 15px;
    margin-top: 5px;
    margin-right: 5px;
}

.card-img {
    padding: 5px;
}

.card-img-top {
    border-radius: 10px;
}

.card-catalog .card-img {
    background: #f6f6f6;
    padding: 5px;
}

.card-catalog .prod-preview {
    display: flex;
    height: auto;
}

.prod-preview img {
    padding: 10px;
    width: 70px;
    background: #000;
}

.prod-preview h3 {
    font-size: 16px;
    margin: 0;
    margin-left: 10px;
}

.prod-preview p {
    color: #adadad;
    font-weight: 100;
    margin-left: 10px;
    margin-bottom: 0px;
}

.card-catalog .card-text {
    margin-bottom: 0px;
}

.footer-item {
    font-weight: 600;
    margin-bottom: 10px;
    padding-bottom: 0px;
}

.card-text {
    padding-bottom: 15px;
}

.time-span {
    position: absolute;
    right: 10px;
    color: #ccc;
    font-size: 13px;
    bottom: 5px;
}

.type-row .card-header.active {
    background: #752de6 !important;
}

.type-row .card-header:hover {
    background: #d0d0d0 !important;
}

.type-row .card-header.active button span {
    color: #fff !important;
}

.type-row .card-header.active button .wrapper-title p {
    color: #fff;
}

.offer-icon {
    width: 50px;
    height: 50px;
    background: #a0f7b3;
    border-radius: 50%;
    margin-left: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.offer-icon span {
    color: #47ad5e;
}

.offer-code {
    color: #c6c6c6;
}

.padding-b-0 {
    padding-bottom: 0px !important;
}

.padding-t-0 {
    padding-top: 0px !important;
}

.btn-whats-message-coupon a span {
    font-size: 15px !important;
    margin-top: 3px !important;
    margin-right: 5px;
}

.btn-whats-message-coupon a {
    font-size: 13px !important;
}

.h-160 {
    height: 160px !important;
}

.card-img-top {
    object-fit: cover;
    background: #000;
}

.video-header {
    width: 271px;
    height: 180px;
    background: #000;
}

.toggle-check .control .control_indicator {
    margin-right: 0px !important;
}

.edit_square_icon {
    font-size: 18px;
    margin: 8px;
}

.img-upload-icon {
    position: absolute;
    right: 30px;
    top: 15px;
}

.img-edit-icon {
    position: absolute;
    right: 30px;
    top: 45px;
}

.img-upload-icon span i {
    color: #fff;
}

.input-example-card {
    font-size: 10px;
    height: 25px;
}

.color-bcbcbc {
    color: #bcbcbc !important;
}

.card-preview {
    overflow-y: auto;
    max-height: 500px;
}

.minutes-info {
    margin-bottom: 0px !important;
    font-size: 13px;
    color: #c1c1c1cc !important;
    margin-top: 5px;
}

.m-b-0 {
    margin-bottom: 0px !important;
}
</style>